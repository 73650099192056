import {
  applyMiddleware,
  CombinedState,
  compose,
  createStore,
  Store,
} from 'redux';
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from 'redux-state-sync';
import thunk from 'redux-thunk';

import reducers, { defaultState, ApplicationState } from './reducers';
export type { ApplicationState } from './reducers';

const stateSyncConfig = {
  blacklist: [],
};

const middlewares = [thunk, createStateSyncMiddleware(stateSyncConfig)];
const reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

const enhancers = compose(
  applyMiddleware(...middlewares),
  reduxDevTools ? reduxDevTools() : (f: any) => f
);

const store = (createStore(
  reducers,
  defaultState,
  enhancers
) as unknown) as Store<CombinedState<ApplicationState>> & {
  dispatch: unknown;
};

initStateWithPrevTab(store);

export default store;
