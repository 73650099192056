import Request, {
  noop,
  setData,
  setMethod,
  setOnUploadProgress,
  setParams,
  setURL,
} from 'src/api/request';
import { API_ROOT } from 'src/constants';
import {
  updateAccountProfileSchema,
  uploadMainImageSchema,
} from './profile.schema';
import {
  AccountProfile,
  MainProfileImageResponse,
  PublicProfile,
  RelationProfile,
  UpdateAccountProfile,
  UpdateMainProfileImage,
} from './types';
import { Resource, ResourceCollection, ResourcePage } from '../types';

export const getAccountProfile = () =>
  Request<Resource<AccountProfile>>(
    setURL(`${API_ROOT}/account/profile`),
    setMethod('GET')
  ).then((response) => response.data);

export const updateAccountProfile = (data: UpdateAccountProfile) =>
  Request<Resource<AccountProfile>>(
    setURL(`${API_ROOT}/account/profile`),
    setMethod('PUT'),
    setData(data, updateAccountProfileSchema)
  ).then((response) => response.data);

export const getProfiles = (params?: any) =>
  Request<ResourcePage<PublicProfile>>(
    setURL(`${API_ROOT}/profile`),
    setMethod('GET'),
    setParams(params)
  ).then((response) => response.data);

export const getProfile = (nameOrUuid: string) =>
  Request<Resource<PublicProfile>>(
    setURL(`${API_ROOT}/profile/${nameOrUuid}`),
    setMethod('GET')
  ).then((response) => response.data);

export const uploadMainProfileImage = (
  data: UpdateMainProfileImage,
  onUploadProgress?: (progressEvent: ProgressEvent) => void
) =>
  Request<Resource<MainProfileImageResponse>>(
    setURL(`${API_ROOT}/account/profile/main-image`),
    setMethod('POST'),
    setData(data, uploadMainImageSchema, true),
    onUploadProgress ? setOnUploadProgress(onUploadProgress) : noop()
  ).then((response) => response.data);

export const profileSearch = (term: string) =>
  Request<ResourceCollection<RelationProfile>>(
    setURL(`${API_ROOT}/profile/search`),
    setMethod('GET'),
    setParams({ term })
  ).then((response) => response.data);
