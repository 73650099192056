import { date, object, ref, string } from 'yup';
import { DateTime } from 'luxon';

import i18n from 'src/i18n';

import { usernameSchema } from '../account';

export const createLoginSchema = object()
  .shape({
    email: string().required().trim(),
    password: string().required(),
  })
  .defined();

export const createRegisterSchema = object()
  .shape({
    name: usernameSchema,
    email: string().email().max(255).required().trim(),
    birthday: date()
      .nullable()
      .max(DateTime.local().minus({ years: 18 }).toISODate(), () =>
        i18n.t('messages.over-18')
      )
      .required(),
    password: string().min(8).required(),
    password_confirmation: string()
      .required()
      .oneOf([ref('password')], () => i18n.t('messages.passwords-match')),
  })
  .defined();

export const createForgotPasswordSchema = object()
  .shape({
    email: string().email().max(255).required().trim(),
  })
  .defined();

export const createResetPasswordSchema = object()
  .shape({
    token: string(),
    email: string().email().max(255).required().trim(),
    password: string().min(8).required(),
    password_confirmation: string()
      .required()
      .oneOf([ref('password')], () => i18n.t('messages.passwords-match')),
  })
  .defined();
