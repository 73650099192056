import { stringify } from 'querystring';
import { createPath } from 'history';

import history from './history';

/**
 * Redirect to login.
 *
 * @param {{}} [query?]
 */
export const redirectToLogin = (query?: {}) => {
  let search = {};
  const redirectUri = `${history.location.pathname}${history.location.search}`;
  if (redirectUri !== '/' && !redirectUri.startsWith('/login')) {
    search = { returnTo: redirectUri };
  }

  history.push(
    createPath({
      pathname: '/login',
      search: stringify({ ...search, ...query }),
    })
  );
};
