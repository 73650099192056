import { Account, UpdateAccountRequest } from 'src/api/account';
import { ApiErrorResponse, Resource } from 'src/api/types';
import { actionCreatorFactory } from 'typescript-fsa';

export const actionCreator = actionCreatorFactory('@@ABY/account');

export const requestAccountActions = actionCreator.async<
  void,
  Resource<Account>,
  ApiErrorResponse
>('request');

export const handleUpdateAccount = actionCreator.async<
  UpdateAccountRequest,
  Resource<Account>,
  ApiErrorResponse
>('update');
