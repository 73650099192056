import {
  AccountProfile,
  MainProfileImageResponse,
  UpdateAccountProfile,
} from 'src/api/profile';
import { ApiErrorResponse, Resource } from 'src/api/types';
import { actionCreatorFactory } from 'typescript-fsa';

export const actionCreator = actionCreatorFactory('@@ABY/profile');

export const getAccountProfileActions = actionCreator.async<
  void,
  Resource<AccountProfile>,
  ApiErrorResponse
>('request');

export const handleUpdateAccountProfile = actionCreator.async<
  UpdateAccountProfile,
  Resource<AccountProfile>,
  ApiErrorResponse
>('update');

export const setProfileMainImage = actionCreator<MainProfileImageResponse>(
  'update-main-image'
);
