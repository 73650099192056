import { Into } from 'src/api/profile-choices';
import { ApiErrorResponse, ResourceCollection } from 'src/api/types';
import { actionCreatorFactory } from 'typescript-fsa';

export const actionCreator = actionCreatorFactory('@@ABY/into');

export const requestIntoActions = actionCreator.async<
  void,
  ResourceCollection<Into>,
  ApiErrorResponse
>('request');
