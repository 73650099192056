import Axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import Bottleneck from 'bottleneck';
import { ObjectSchema, ValidationError } from 'yup';
import { ApiErrorResponse, ApiError } from './types';

interface RequestConfig extends AxiosRequestConfig {
  validationErrors?: ApiErrorResponse;
}

type ConfigField =
  | 'headers'
  | 'data'
  | 'params'
  | 'method'
  | 'url'
  | 'onUploadProgress';

const limiter = new Bottleneck({
  reservoir: 60,
  reservoirRefreshAmount: 60,
  reservoirRefreshInterval: 1000, // Must be divisible by 250.

  maxConcurrent: 30,
});

export const baseRequest = Axios.create({
  baseURL: 'https://api.aby.com/api/v1',
});

const set = (field: ConfigField, value: any) => (object: any) => {
  return !isEmpty(value) ? { ...object, [field]: value } : object;
};

const append = (field: ConfigField, key: string, value: any) => (
  object: any
) => {
  return !isEmpty(value)
    ? {
        ...object,
        [field]: {
          ...object[field],
          [key]: value,
        },
      }
    : object;
};

export const isEmpty = (v: any) =>
  v === undefined ||
  v === null ||
  v.length === 0 ||
  (typeof v === 'object' &&
    Object.keys(v).length === 0 &&
    v.constructor === Object);

/** URL */
export const setURL = (url: string) => set('url', url);

/** METHOD */
export const setMethod = (method: 'GET' | 'POST' | 'PUT' | 'DELETE') =>
  set('method', method);

/** Set fake method (PHP won't process files in the input stream unless it's a POST) */
export const setFakeMethod = (method: 'PUT') =>
  append('data', '_method', method);

/** Params */
export const setParams = (params: any = {}) => set('params', params);

/** Headers */
export const setHeaders = (newHeaders: any = {}) => (object: any) => {
  return !isEmpty(newHeaders)
    ? { ...object, headers: { ...object.headers, ...newHeaders } }
    : object;
};

/** Upload progress callback */
export const setOnUploadProgress = (
  progressCallback: (progressEvent: ProgressEvent) => void
) => set('onUploadProgress', progressCallback);

/** No operation, for optional request function ternaries */
export const noop = () => (object: any) => object;

/**
 * Convert data to FormData
 * @param object
 */
const convertToFormData = (object: any) => {
  if (isEmpty(object)) return object;

  const formData = new FormData();
  for (const [key, value] of Object.entries(object)) {
    // @ts-ignore
    formData.append(key, value);
  }

  return formData;
};

/**
 * Validate and set data in the request configuration object.
 */
export const setData = <T extends {}>(
  data: T,
  /**
   * If a schema is provided, execute its validation method. If the validation
   * fails, the errors will be set at L.validationError's path.
   */
  schema?: ObjectSchema<T>,
  /**
   * Use form data instead of JSON.
   */
  useFormData?: boolean
) => {
  if (!schema) {
    return set('data', useFormData ? convertToFormData(data) : data);
  }

  try {
    schema.validateSync(data, { abortEarly: false });
    return set('data', useFormData ? convertToFormData(data) : data);
  } catch (error) {
    return (object: any) => ({
      ...object,
      data,
      validationErrors: convertYupToAPIErrors(error),
    });
  }
};

export const setFile = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return set('data', formData);
};

/**
 * Attempt to convert Yup error to our pattern. The only magic here is the
 * recursive call to itself since we have nested structures.
 */
const convertYupToAPIErrors = (
  validationError: ValidationError
): ApiErrorResponse => {
  const { inner } = validationError;

  const errorResponse: ApiErrorResponse = { errors: [] };

  if (inner && inner.length > 0) {
    // If aggregate errors.
    errorResponse.errors = inner.reduce(
      (result: ApiError[], innerValidationError) => {
        const err = convertYupToAPIErrors(innerValidationError);
        return Array.isArray(err) ? [...result, ...err] : [...result, err];
      },
      []
    );
  } else {
    // If single error.
    errorResponse.errors = [mapYupToAPIError(validationError)];
  }

  return errorResponse;
};

const mapYupToAPIError = ({ message, path }: ValidationError): ApiError => ({
  reason: [message],
  ...(path && { field: path }),
});

/**
 * Builds up a config starting from a default object and applying each of the
 * functions.
 *
 * URL is defaulted; otherwise all requests will fail unless setURL() is used in
 * the chain.
 *
 * Config is defaulted to an empty object because setHeaders() merges with the
 * existing headers object, unlike all other setters which directly assign the
 * value. If setHeaders() is called and no headers are present, the result is an
 * error.
 *
 * @param fns An array of functions to be applied to the config object.
 */
const reduceRequestConfig = (...fns: Function[]): RequestConfig =>
  fns.reduceRight((result, fn) => fn(result), {
    url: 'https://api.aby.com/api/v1',
    headers: {},
  });

/** Generator */
export const requestGenerator = <T>(...fns: Function[]): AxiosPromise<T> => {
  const config = reduceRequestConfig(...fns);
  if (config.validationErrors) {
    return Promise.reject(
      config.validationErrors // All failed requests, client or server errors, should be ApiErrorResponse.
    );
  }
  return limiter.schedule(() => baseRequest(config));
};

export default requestGenerator;
