import Request, {
  setData,
  setMethod,
  setParams,
  setURL,
} from 'src/api/request';
import { API_ROOT } from 'src/constants';
import { createThreadSchema, createMessageSchema } from './messenger.schema';
import { CreateMessage, CreateThread, Thread, Message } from './types';
import { Resource, ResourceCursorPage, RequestSuccess } from '../types';
import { MessengerMessage, MessengerMessagesUpdated } from '../event-types';

export const getThreads = (params?: any) =>
  Request<ResourceCursorPage<Thread>>(
    setURL(`${API_ROOT}/messenger/threads`),
    setMethod('GET'),
    setParams(params)
  ).then((response) => response.data);

export const getThreadMessages = (threadUuid: string, params?: any) =>
  Request<ResourceCursorPage<Message>>(
    setURL(`${API_ROOT}/messenger/threads/${threadUuid}/messages`),
    setMethod('GET'),
    setParams(params)
  ).then((response) => response.data);

export const getThreadForProfile = (profileUuid: string) =>
  Request<Resource<Thread>>(
    setURL(`${API_ROOT}/messenger/thread-for-profile/${profileUuid}`),
    setMethod('GET')
  ).then((response) => response.data);

export const createThread = (data: CreateThread) =>
  Request<Resource<MessengerMessage>>(
    setURL(`${API_ROOT}/messenger/threads`),
    setMethod('POST'),
    setData(data, createThreadSchema)
  ).then((response) => response.data);

export const createMessage = (threadUuid: string, data: CreateMessage) =>
  Request<Resource<MessengerMessage>>(
    setURL(`${API_ROOT}/messenger/threads/${threadUuid}/messages`),
    setMethod('POST'),
    setData(data, createMessageSchema)
  ).then((response) => response.data);

export const getUnreadMessagesCount = () =>
  Request<Resource<MessengerMessagesUpdated>>(
    setURL(`${API_ROOT}/messenger/threads/read`),
    setMethod('GET')
  ).then((response) => response.data);

export const markThreadRead = (threadUuid: string) =>
  Request<Resource<RequestSuccess>>(
    setURL(`${API_ROOT}/messenger/threads/read/${threadUuid}`),
    setMethod('PUT')
  ).then((response) => response.data);
