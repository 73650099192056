import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { redirectToLogin } from 'src/session';
import { authentication } from 'src/utilities/storage';
import {
  handleInitTokens,
  handleLogout,
  handleRefreshReduxTokens,
  handleRefreshToken,
  handleStartSession,
  handleUpdateUserSiteState,
} from './authentication.actions';

import { clearLocalStorage } from './authentication.helpers';
import { State } from './index';

import { updateAuthToken } from 'src/echo-client';

export const defaultState: State = {
  token: null,
  expires: null,
  site_state: null,
};

const {
  token: tokenInLocalStorage,
  expires: expiresInLocalStorage,
} = authentication;

const reducer = reducerWithInitialState(defaultState)
  .cases([handleStartSession, handleRefreshToken], (state, payload) => {
    const { access_token, expires_in, site_state } = payload;

    // Set local storage.
    tokenInLocalStorage.set(access_token || '');
    const expires = new Date();
    expires.setSeconds(expires.getSeconds() + expires_in);
    expiresInLocalStorage.set(expires.toISOString());

    // Update Echo auth token
    updateAuthToken(access_token);

    // Set redux state
    return {
      ...state,
      token: access_token || null,
      expires: expires.toISOString() || null,
      site_state,
    };
  })
  .case(handleInitTokens, (state) => {
    // For now, disabling this since we're going to try and refresh the token.
    // A failing request will, after one retry, redirect to the login page.

    // If our token is expired, clear local storage and redux state.
    /*const expiryDateFromLocalStorage = expiresInLocalStorage.get();
    const expiryDate = new Date(expiryDateFromLocalStorage);
    if (expiryDateFromLocalStorage && expiryDate < new Date()) {
      updateAuthToken(null);
      redirectToLogin();
      return defaultState;
    }*/

    // Otherwise just set redux state to what's in local storage currently.
    // may be null value here but that's okay.
    const token = tokenInLocalStorage.get();
    const expires = expiresInLocalStorage.get();

    // If we have no token in local storage, send to login.
    if (!token) {
      updateAuthToken(null);
      redirectToLogin();
      return defaultState;
    }

    updateAuthToken(token);

    return {
      ...state,
      token,
      expires,
    };
  })
  .case(handleUpdateUserSiteState, (state, payload) => {
    return {
      ...state,
      site_state: payload,
    };
  })
  .case(handleLogout, (state) => {
    // Clear local storage and redux state.
    clearLocalStorage();
    updateAuthToken(null);

    return defaultState;
  })
  .case(handleRefreshReduxTokens, (state) => {
    const localToken = tokenInLocalStorage.get(),
      localExpires = expiresInLocalStorage.get();

    updateAuthToken(localToken);

    return {
      ...state,
      token: localToken,
      expires: localExpires,
    };
  })
  .default((state) => state);

export default reducer;
