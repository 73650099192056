import {
  ForgotPasswordRequest,
  ForgotPasswordSuccess,
  LoginRequest,
  RegisterRequest,
  ResetPasswordRequest,
  LoginResponse,
  UserSiteState,
} from 'src/api/authentication';
import { ApiErrorResponse, RequestSuccess } from 'src/api/types';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('@@ABY/authentication');

export const loginActions = actionCreator.async<
  LoginRequest,
  LoginResponse,
  ApiErrorResponse
>('login');

export const registerActions = actionCreator.async<
  RegisterRequest,
  RequestSuccess,
  ApiErrorResponse
>('register');

export const forgotPasswordActions = actionCreator.async<
  ForgotPasswordRequest,
  ForgotPasswordSuccess,
  ApiErrorResponse
>('forgot-password');

export const resetPasswordActions = actionCreator.async<
  ResetPasswordRequest,
  LoginResponse,
  ApiErrorResponse
>('reset-password');

/** user is coming to the app for the first time */
export const handleStartSession = actionCreator<LoginResponse>('START_SESSION');

/** user is refreshing the page and redux state needs to be synced with local storage */
export const handleInitTokens = actionCreator('INIT_TOKENS');

/** token was refreshed */
export const handleRefreshToken = actionCreator<LoginResponse>('REFRESH_TOKEN');

/** set redux state to what's in local storage */
export const handleRefreshReduxTokens = actionCreator('REFRESH_REDUX_TOKENS');

/** update the user's site state */
export const handleUpdateUserSiteState = actionCreator<UserSiteState>(
  'UPDATE_USER_SITE_STATE'
);

/** log the user out */
export const handleLogout = actionCreator('LOGOUT');
