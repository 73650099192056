import produce from 'immer';
import { Reducer } from 'redux';
import { isType } from 'typescript-fsa';

import { AccountProfile } from 'src/api/profile';
import { RequestableDataWithEntityError } from 'src/store/types';
import {
  getAccountProfileActions,
  handleUpdateAccountProfile,
  setProfileMainImage,
} from './profile.actions';

/**
 * State
 */

export type State = RequestableDataWithEntityError<AccountProfile>;

export const defaultState: State = {
  loading: false,
  error: {},
  lastUpdated: 0,
  data: undefined,
};

const reducer: Reducer<State> = (state = defaultState, action) => {
  return produce(state, (draft) => {
    /** Get/Update profile */

    if (
      isType(action, getAccountProfileActions.started) ||
      isType(action, handleUpdateAccountProfile.started)
    ) {
      draft.loading = true;
      draft.error.read = undefined;
    }

    if (
      isType(action, getAccountProfileActions.done) ||
      isType(action, handleUpdateAccountProfile.done)
    ) {
      const { result } = action.payload;
      draft.loading = false;
      draft.lastUpdated = Date.now();
      draft.data = result.data;
    }

    if (
      isType(action, getAccountProfileActions.failed) ||
      isType(action, handleUpdateAccountProfile.failed)
    ) {
      const { error } = action.payload;
      draft.loading = false;
      draft.error.read = error;
    }

    /** Set main image url */

    if (isType(action, setProfileMainImage)) {
      if (draft.data) {
        const {
          main_image,
          main_image_url,
          avatar_crop,
          profile_crop,
        } = action.payload;
        draft.data.main_image = main_image;
        draft.data.main_image_url = main_image_url;
        draft.data.profile_crop = profile_crop;
        draft.data.avatar_crop = avatar_crop;
      }
    }
  });
};

export default reducer;
