import { Community } from 'src/api/community';
import { ApiErrorResponse, ResourceCollection } from 'src/api/types';
import { actionCreatorFactory } from 'typescript-fsa';

export const actionCreator = actionCreatorFactory('@@ABY/community');

export const requestCommunityActions = actionCreator.async<
  void,
  ResourceCollection<Community>,
  ApiErrorResponse
>('request');
