import { ApiError } from 'src/api/types';

interface Intercept {
  condition: (e: ApiError) => boolean;
  replacementText: JSX.Element | string;
  /** optional callback to fire when error is matched correctly */
  callback?: Function;
}

export const interceptErrors = (
  errors: ApiError[],
  interceptors: Intercept[]
) => {
  return errors.map((thisError) => {
    return interceptors.reduce((acc, eachInterceptor) => {
      if (eachInterceptor.condition(thisError)) {
        acc = {
          reason: [eachInterceptor.replacementText as string],
        };
        if (eachInterceptor.callback) {
          eachInterceptor.callback();
        }
      }
      return acc;
    }, thisError);
  });
};
