import { ApiError } from 'src/api/types';

/**
 * Convert Laravel errors to ApiError[].
 *
 * Laravel's errors are returned in the format
 *
 * {
 *   fieldname: [errors]
 * }
 *
 * @param errors
 */
export const convertLaravelErrors = (errors: any): ApiError[] => {
  return Object.entries(errors).reduce(
    (acc: any, [key, value]) => [
      ...acc,
      {
        field: key,
        reason: [value],
      },
    ],
    [] as ApiError[]
  );
};
