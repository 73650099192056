import * as React from 'react';
import { useSelector } from 'react-redux';

import { ApplicationState } from 'src/store';

const SiteLoadGuard: React.FC = ({ children }) => {
  const userSiteState = useSelector(
    (state: ApplicationState) => state.authentication.site_state
  );

  if (!userSiteState) return <></>;

  return userSiteState.must_verify_email ? <></> : <>{children}</>;
};

export default SiteLoadGuard;
