import Request, {
  setData,
  setMethod,
  setParams,
  setURL,
} from 'src/api/request';
import { API_ROOT } from 'src/constants';
import { RequestSuccess, ResourcePage } from '../types';
import { RelationProfile } from '../profile';

export const getAccountFriends = (params?: any) =>
  Request<ResourcePage<RelationProfile>>(
    setURL(`${API_ROOT}/account/profile/friends`),
    setMethod('GET'),
    setParams(params)
  ).then((response) => response.data);

export const getProfileFriends = (profileUuid: string, params?: any) =>
  Request<ResourcePage<RelationProfile>>(
    setURL(`${API_ROOT}/profile/${profileUuid}/friends`),
    setMethod('GET'),
    setParams(params)
  ).then((response) => response.data);

export const removeFriend = (profileUuid: string) =>
  Request<RequestSuccess>(
    setURL(`${API_ROOT}/account/profile/friends/${profileUuid}`),
    setMethod('DELETE')
  ).then((response) => response);

export const getFriendRequests = (params?: any) =>
  Request<ResourcePage<RelationProfile>>(
    setURL(`${API_ROOT}/account/profile/friend-requests`),
    setMethod('GET'),
    setParams(params)
  ).then((response) => response.data);

export const confirmFriendship = (profileUuid: string) =>
  Request<RequestSuccess>(
    setURL(
      `${API_ROOT}/account/profile/friend-requests/${profileUuid}/confirm`
    ),
    setMethod('POST')
  ).then((response) => response);

export const denyFriendRequest = (profileUuid: string) =>
  Request<RequestSuccess>(
    setURL(`${API_ROOT}/account/profile/friend-requests/${profileUuid}`),
    setMethod('DELETE')
  ).then((response) => response);

export const requestFriendship = (profileUuid: string) =>
  Request<RequestSuccess>(
    setURL(`${API_ROOT}/account/profile/friends-requested`),
    setMethod('POST'),
    setData({ uuid: profileUuid })
  ).then((response) => response);

export const cancelFriendRequest = (profileUuid: string) =>
  Request<RequestSuccess>(
    setURL(`${API_ROOT}/account/profile/friends-requested/${profileUuid}`),
    setMethod('DELETE')
  ).then((response) => response);
