import produce from 'immer';
import { Reducer } from 'redux';
import { isType } from 'typescript-fsa';

import { Account } from 'src/api/account';
import { RequestableDataWithEntityError } from 'src/store/types';
import { requestAccountActions, handleUpdateAccount } from './account.actions';

/**
 * State
 */
export type State = RequestableDataWithEntityError<Account>;

export const defaultState: State = {
  loading: false,
  error: {},
  lastUpdated: 0,
  data: undefined,
};

/**
 * Reducer
 */
const reducer: Reducer<State> = (state: State = defaultState, action) => {
  return produce(state, (draft) => {
    if (
      isType(action, requestAccountActions.started) ||
      isType(action, handleUpdateAccount.started)
    ) {
      draft.loading = true;
    }

    if (
      isType(action, requestAccountActions.done) ||
      isType(action, handleUpdateAccount.done)
    ) {
      const { result } = action.payload;

      draft.loading = false;
      draft.data = result.data;
      draft.lastUpdated = Date.now();
      draft.error.read = undefined;
    }

    if (
      isType(action, requestAccountActions.failed) ||
      isType(action, handleUpdateAccount.failed)
    ) {
      const { error } = action.payload;

      draft.loading = false;
      draft.error.read = error;
    }
  });
};

export default reducer;
