import { object, string } from 'yup';

export const createThreadSchema = object()
  .shape({
    recipient: string().required(),
    body: string().required(),
  })
  .defined();

export const createMessageSchema = object()
  .shape({
    body: string(),
  })
  .defined();
