import {
  getAccountInfo,
  updateAccount as _updateAccount,
} from 'src/api/account';

import { createRequestThunk } from 'src/store/store.helpers';
import { requestAccountActions, handleUpdateAccount } from './account.actions';

export const requestAccount = createRequestThunk(
  requestAccountActions,
  getAccountInfo
);

export const updateAccount = createRequestThunk(
  handleUpdateAccount,
  _updateAccount
);
