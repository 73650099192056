export const API_ROOT =
  process.env.REACT_APP_API_ROOT || 'https://api.aby.com/api/v1';

export const BROADCAST_AUTH =
  process.env.REACT_APP_BROADCAST_AUTH || 'https://api.aby.com/broadcast/auth';

export const BROADCAST_HOST =
  process.env.REACT_APP_BROADCAST_HOST || 'api.aby.com';

export const BROADCAST_KEY = process.env.REACT_APP_BROADCAST_KEY || '';

export const BROADCAST_TLS = process.env.REACT_APP_BROADCAST_TLS === 'true';

// Default error message for non-API errors
export const DEFAULT_ERROR_MESSAGE = 'An unexpected error occurred.';
