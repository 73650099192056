import { combineReducers } from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

import account, {
  defaultState as defaultAccountState,
  State as AccountState,
} from 'src/store/account/account.reducer';

import { State as AuthState } from 'src/store/authentication';
import authentication, {
  defaultState as authenticationDefaultState,
} from 'src/store/authentication/authentication.reducer';

import initialLoad, {
  defaultState as initialLoadState,
  State as InitialLoadState,
} from './initialLoad/initialLoad.reducer';

import profile, {
  defaultState as defaultProfileState,
  State as ProfileState,
} from './profile/profile.reducer';

import friendRequests, {
  defaultState as defaultFriendRequestsState,
  State as FriendRequestsState,
} from './friend-requests/friend-requests.reducer';

import messenger, {
  defaultState as defaultMessengerState,
  State as MessengerState,
} from './messenger/messenger.reducer';

/* Profile Choices */

import contentCategories, {
  defaultState as defaultContentCategoriesState,
  State as ContentCategoriesState,
} from './content-categories/content-categories.reducer';
import genders, {
  defaultState as defaultGendersState,
  State as GendersState,
} from './genders/genders.reducer';
import into, {
  defaultState as defaultIntoState,
  State as IntoState,
} from './into/into.reducer';
import orientations, {
  defaultState as defaultOrientationsState,
  State as OrientationsState,
} from './orientations/orientations.reducer';
import relationshipRoles, {
  defaultState as defaultRelationshipRolesState,
  State as RelationshipRolesState,
} from './relationship-roles/relationship-roles.reducer';
import relationshipStatuses, {
  defaultState as defaultRelationshipStatusesState,
  State as RelationshipStatusesState,
} from './relationship-statuses/relationship-statuses.reducer';
import seeking, {
  defaultState as defaultSeekingState,
  State as SeekingState,
} from './seeking/seeking.reducer';

/* Site */

import communities, {
  defaultState as defaultCommunitiesState,
  State as CommunitiesState,
} from './communities/communities.reducer';

import reportTypes, {
  defaultState as defaultReportTypesState,
  State as ReportTypesState,
} from './report-types/report-types.reducer';

/**
 * Default State
 */
const __resourcesDefaultState = {
  account: defaultAccountState,
  profile: defaultProfileState,
  contentCategories: defaultContentCategoriesState,
  genders: defaultGendersState,
  into: defaultIntoState,
  orientations: defaultOrientationsState,
  relationshipRoles: defaultRelationshipRolesState,
  relationshipStatuses: defaultRelationshipStatusesState,
  seeking: defaultSeekingState,
  communities: defaultCommunitiesState,
  reportTypes: defaultReportTypesState,
};

interface ResourcesState {
  account: AccountState;
  profile: ProfileState;
  contentCategories: ContentCategoriesState;
  genders: GendersState;
  into: IntoState;
  orientations: OrientationsState;
  relationshipRoles: RelationshipRolesState;
  relationshipStatuses: RelationshipStatusesState;
  seeking: SeekingState;
  communities: CommunitiesState;
  reportTypes: ReportTypesState;
}

export interface ApplicationState {
  __resources: ResourcesState;
  authentication: AuthState;
  initialLoad: InitialLoadState;
  friendRequests: FriendRequestsState;
  messenger: MessengerState;
}

export const defaultState: ApplicationState = {
  __resources: __resourcesDefaultState,
  authentication: authenticationDefaultState,
  initialLoad: initialLoadState,
  friendRequests: defaultFriendRequestsState,
  messenger: defaultMessengerState,
};

/**
 * Reducers
 */
const __resources = combineReducers({
  account,
  profile,
  contentCategories,
  genders,
  into,
  orientations,
  relationshipRoles,
  relationshipStatuses,
  seeking,
  communities,
  reportTypes,
});

const reducers = combineReducers<ApplicationState>({
  __resources,
  authentication,
  initialLoad,
  friendRequests,
  messenger,
});

export default withReduxStateSync(reducers);
