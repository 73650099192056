import actionCreatorFactory from 'typescript-fsa';
import { ApiErrorResponse, ResourcePage } from 'src/api/types';
import { RelationProfile } from 'src/api/profile';

const actionCreator = actionCreatorFactory('@@ABY/friend-requests');

export const getFriendRequestsCount = actionCreator.async<
  void,
  ResourcePage<RelationProfile>,
  ApiErrorResponse
>('request');

export const setFriendRequestsCount = actionCreator<number>('update');
