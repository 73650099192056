import { AxiosError, AxiosResponse } from 'axios';

import i18n from './i18n';
import { API_ROOT, DEFAULT_ERROR_MESSAGE } from 'src/constants';

import { baseRequest } from 'src/api';
import { ApiErrorResponse } from 'src/api/types';
import { refresh } from 'src/api/authentication';

import store from 'src/store';
import { Dispatch } from 'src/types';
import {
  handleRefreshToken,
  handleLogout,
} from 'src/store/authentication/authentication.actions';
import { requestAccountProfile } from 'src/store/profile/profile.requests';

import { convertLaravelErrors } from 'src/utilities/convertLaravelErrors';
import { interceptErrors } from 'src/utilities/interceptAPIError';

const handleSuccess: <T extends AxiosResponse>(response: T) => T = (
  response
) => {
  return response;
};

const handleError = (error: AxiosError) => {
  if (error.response && error.response.status === 401) {
    const dispatch: Dispatch = store.dispatch;
    const originalRequestConfig = error.config;

    // Prevent loop.
    if (originalRequestConfig.url?.endsWith('/auth/refresh')) {
      dispatch(handleLogout());
      return Promise.reject(error.response);
    }

    // Try refreshing token once and then replaying the request.
    if (!originalRequestConfig.headers['X-Retry']) {
      originalRequestConfig.headers['X-Retry'] = true;

      return refresh().then((response) => {
        dispatch(handleRefreshToken(response));

        // Assume we also need to reload account profile, since the avatar image
        // will probably have expired.
        dispatch(requestAccountProfile());
        return baseRequest(originalRequestConfig);
      });
    }

    /**
     * This will remove redux state and the useEffect update in
     * AuthenticationWrapper.tsx will be responsible for redirecting to Login
     */

    dispatch(handleLogout());
  }

  const response: ApiErrorResponse = {
    errors: [{ reason: [DEFAULT_ERROR_MESSAGE] }],
  };

  if (error.response && error.response?.data?.type) {
    response.type = error.response.data.type;
  } else if (error.message) {
    // This would be a plain Axios error, like a network error.
    response.errors = [{ reason: [error.message] }];
  }

  if (error.response?.data?.errors) {
    response.errors = convertLaravelErrors(error.response?.data?.errors);
  } else if (error.response?.data?.message) {
    response.errors = convertLaravelErrors({
      message: error.response?.data?.message,
    });
  }

  response.errors = interceptErrors(response.errors, []);

  return Promise.reject(response);
};

/** Request interceptor */
baseRequest.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.authentication?.token ?? '';

  let finalUrl = '';

  /**
   * override the base URL with the one coming from the .env file.
   */
  if (config.url && config.baseURL) {
    finalUrl = config.url.replace(config.baseURL, API_ROOT);
  }

  return {
    ...config,
    url: finalUrl || config.url,
    headers: {
      ...config.headers,
      ...(token && { Authorization: `Bearer ${token}` }),
      'X-Locale': i18n.language,
    },
  };
});

/** Response interceptor */
baseRequest.interceptors.response.use(handleSuccess, handleError);
