import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AuthenticationWrapper from 'src/components/AuthenticationWrapper';
import DataLoader from 'src/components/SiteDataLoader/DataLoader';
import SiteLoadGuard from 'src/components/SiteDataLoader/SiteLoadGuard';
import SplashScreen from 'src/components/SplashScreen';
import Loading from 'src/components/Loading';

const Logout = React.lazy(() => import('src/pages/Auth/Logout'));
const Login = React.lazy(() => import('src/pages/Auth/Login'));
const Register = React.lazy(() => import('src/pages/Auth/Register'));
const ForgotPassword = React.lazy(
  () => import('src/pages/Auth/ForgotPassword')
);
const ResetPassword = React.lazy(() => import('src/pages/Auth/ResetPassword'));
const Privacy = React.lazy(() => import('src/pages/Privacy'));
const Terms = React.lazy(() => import('src/pages/Terms'));
const Email = React.lazy(() => import('src/pages/Email'));

const App = React.lazy(() => import('./App'));

const AppRoutes: React.FC<{}> = () => (
  <React.Suspense fallback={<Loading />}>
    <Switch>
      {/* Removes trailing slashes */}
      <Route
        path="/:url*(/+)"
        exact
        strict
        render={({ location }) => (
          <Redirect to={location.pathname.replace(/\/+$/, '')} />
        )}
      />

      {/* Removes duplicate slashes */}
      <Route
        path="(.*//+.*)"
        exact
        strict
        render={({ location }) => (
          <Redirect to={location.pathname.replace(/\/\/+/g, '/')} />
        )}
      />

      <Route exact path="/logout">
        <Logout />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/register">
        <Register />
      </Route>
      <Route exact path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route exact path="/reset-password/:token">
        <ResetPassword />
      </Route>
      <Route exact path="/privacy">
        <Privacy />
      </Route>
      <Route exact path="/terms">
        <Terms />
      </Route>

      {/* Authenticated routes */}
      <AuthenticationWrapper>
        <Switch>
          {/* Routes that should not load site data */}
          <Route path="/email">
            <Email />
          </Route>

          {/* Site routes requiring data */}
          <SiteLoadGuard>
            <DataLoader>
              <>
                <App />
                <SplashScreen />
              </>
            </DataLoader>
          </SiteLoadGuard>
        </Switch>
      </AuthenticationWrapper>
    </Switch>
  </React.Suspense>
);

export default AppRoutes;
