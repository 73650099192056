import {
  forgotPassword,
  login,
  logout,
  refresh,
  register,
  resetPassword,
} from 'src/api/authentication';
import { RequestSuccess } from 'src/api/types';
import { LoginResponse } from 'src/api/authentication/types';
import { ThunkActionCreator } from 'src/store/types';
import {
  loginActions,
  handleRefreshToken,
  handleLogout as _handleLogout,
  registerActions,
  forgotPasswordActions,
  resetPasswordActions,
} from './authentication.actions';
import { createRequestThunk } from 'src/store/store.helpers';
import history from 'src/history';

export const handleLogin = createRequestThunk(loginActions, (data) =>
  login(data)
);

export const handleRegister = createRequestThunk(registerActions, (data) =>
  register(data)
);

export const handleForgotPassword = createRequestThunk(
  forgotPasswordActions,
  (data) => forgotPassword(data)
);

export const handleResetPassword = createRequestThunk(
  resetPasswordActions,
  (data) => resetPassword(data)
);

export const refreshAuthToken: ThunkActionCreator<
  Promise<LoginResponse>
> = () => (dispatch) => {
  return refresh()
    .then((response) => {
      dispatch(handleRefreshToken(response));
      return response;
    })
    .catch((err) => {
      dispatch(_handleLogout());
      history.replace('/');
      return err;
    });
};

export const handleLogout: ThunkActionCreator<Promise<RequestSuccess>> = () => (
  dispatch
) => {
  return logout()
    .then((response) => {
      dispatch(_handleLogout());
      history.replace('/');
      return response;
    })
    .catch((err) => {
      dispatch(_handleLogout());
      history.replace('/');
      return err;
    });
};
