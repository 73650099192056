import { setLocale } from 'yup';
import i18n from './i18n';

const transField = (path: string | undefined) =>
  path ? i18n.t(`fields.${path}`) : path;

setLocale({
  mixed: {
    default: ({ path }: { path: string }) =>
      i18n.t('forms.validation.mixed.default', { path: transField(path) }),
    required: ({ path }: { path: string }) =>
      i18n.t('forms.validation.mixed.required', { path: transField(path) }),
    oneOf: ({ path }: { path: string }) =>
      i18n.t('forms.validation.mixed.oneOf', { path: transField(path) }),
    notOneOf: ({ path }: { path: string }) =>
      i18n.t('forms.validation.mixed.notOneOf', { path: transField(path) }),
    // Not translating `notType`
    defined: ({ path }: { path: string }) =>
      i18n.t('forms.validation.mixed.defined', { path: transField(path) }),
  },

  string: {
    length: ({ path, length }: { path: string; length: number }) =>
      i18n.t('forms.validation.string.length', {
        path: transField(path),
        length,
      }),
    min: ({ path, min }: { path: string; min: number }) =>
      i18n.t('forms.validation.string.min', { path: transField(path), min }),
    max: ({ path, max }: { path: string; max: number }) =>
      i18n.t('forms.validation.string.max', { path: transField(path), max }),
    matches: ({ path, regex }: { path: string; regex: RegExp }) =>
      i18n.t('forms.validation.string.matches', {
        path: transField(path),
        regex,
      }),
    email: ({ path }: { path: string }) =>
      i18n.t('forms.validation.string.email', { path: transField(path) }),
    url: ({ path }: { path: string }) =>
      i18n.t('forms.validation.string.url', { path: transField(path) }),
    trim: ({ path }: { path: string }) =>
      i18n.t('forms.validation.string.trim', { path: transField(path) }),
    lowercase: ({ path }: { path: string }) =>
      i18n.t('forms.validation.string.lowercase', { path: transField(path) }),
    uppercase: ({ path }: { path: string }) =>
      i18n.t('forms.validation.string.uppercase', { path: transField(path) }),
  },

  number: {
    min: ({ path, min }: { path: string; min: number }) =>
      i18n.t('forms.validation.number.min', { path: transField(path), min }),
    max: ({ path, max }: { path: string; max: number }) =>
      i18n.t('forms.validation.number.max', { path: transField(path), max }),
    lessThan: ({ path, less }: { path: string; less: number }) =>
      i18n.t('forms.validation.number.lessThan', {
        path: transField(path),
        less,
      }),
    moreThan: ({ path, more }: { path: string; more: number }) =>
      i18n.t('forms.validation.number.moreThan', {
        path: transField(path),
        more,
      }),
    positive: ({ path }: { path: string }) =>
      i18n.t('forms.validation.number.positive', { path: transField(path) }),
    negative: ({ path }: { path: string }) =>
      i18n.t('forms.validation.number.negative', { path: transField(path) }),
    integer: ({ path }: { path: string }) =>
      i18n.t('forms.validation.number.integer', { path: transField(path) }),
  },

  date: {
    min: ({ path, min }: { path: string; min: number }) =>
      i18n.t('forms.validation.date.min', { path: transField(path), min }),
    max: ({ path, max }: { path: string; max: number }) =>
      i18n.t('forms.validation.date.max', { path: transField(path), max }),
  },

  boolean: {
    isValue: ({ path, value }: { path: string; value: boolean }) =>
      i18n.t('forms.validation.boolean.isValue', {
        path: transField(path),
        value,
      }),
  },

  object: {
    // Not translating `noUnknown`
  },

  array: {
    min: ({ path, min }: { path: string; min: number }) =>
      i18n.t('forms.validation.array.min', { path: transField(path), min }),
    max: ({ path, max }: { path: string; max: number }) =>
      i18n.t('forms.validation.array.max', { path: transField(path), max }),
  },
});
