import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { IconContext } from 'react-icons';
import { Toaster } from 'react-hot-toast';
import Modal from 'react-modal';

// Import our css.
import './css/tailwind.output.css';

// Initialize i18n.
import './i18n';
// Yup locale has to be set before schemas are created.
import './yup-locale';

// Request handlers.
import 'src/request';

import store from './store';
import history from './history';

import reportWebVitals from './reportWebVitals';

import ScrollToTop from 'src/components/core/ScrollToTop';

import AppRoutes from './AppRoutes';

Modal.setAppElement('#root');

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <IconContext.Provider value={{ className: 'aby-svg-icon' }}>
            <>
              <Toaster />
              <ScrollToTop />
              <AppRoutes />
            </>
          </IconContext.Provider>
        </QueryParamProvider>
      </Router>
    </Provider>
  </StrictMode>,
  document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
