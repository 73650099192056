import * as React from 'react';
import classNames from 'classnames';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';

export type SpinSize = 'small' | 'default' | 'large';

export interface SpinProps {
  spinning?: boolean;
  size?: SpinSize;
}

const spinnerClassMap = {
  small: 'w-4 h-4',
  default: 'w-5 h-5',
  large: 'w-8 h-8',
};

const Spin: React.FC<SpinProps> = ({
  spinning = true,
  size = 'default' as SpinSize,
  children,
  ...rest
}) => {
  const spinnerClassName = classNames(
    'inline-block',
    'animate-spin',
    'text-gray-500',
    spinnerClassMap[size]
  );

  const isNestedPattern = () => !!children;

  const spinElement = (
    <div {...rest}>
      <AiOutlineLoading3Quarters className={spinnerClassName} />
    </div>
  );

  if (isNestedPattern()) {
    const containerClassName = classNames(
      'z-0 transition-opacity duration-200 ease-in-out',
      spinning ? 'opacity-30' : 'opacity-1'
    );
    return (
      <div className={classNames('relative')} {...rest}>
        {spinning && (
          <div
            className={classNames(
              'z-10 absolute inset-0 flex items-center justify-center'
            )}
          >
            {spinElement}
          </div>
        )}
        <div className={containerClassName}>{children}</div>
      </div>
    );
  }

  return spinElement;
};

export default Spin;
