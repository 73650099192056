import { object, ref, string } from 'yup';

import i18n from 'src/i18n';

export const usernameSchema = string()
  .required()
  .trim()
  .max(15)
  .matches(/^[A-Za-z0-9-_]*$/, {
    excludeEmptyString: true,
    message: () => i18n.t('messages.username-characters'),
  });

export const updateAccountSchema = object()
  .shape({
    current_password: string().required().trim(),
    name: usernameSchema,
    email: string().required().email().max(255).trim(),
    password: string().notRequired().min(8),
    password_confirmation: string().oneOf([ref('password')], () =>
      i18n.t('messages.passwords-match')
    ),
  })
  .defined();
