import * as React from 'react';
import { memo } from 'react';
import classNames from 'classnames';

import Spin from 'src/components/core/Spin';

interface LoadingProps {
  fullScreen?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ fullScreen = true }) => (
  <div
    className={classNames(
      'text-center',
      fullScreen
        ? [
            'min-h-screen',
            'flex',
            'justify-center',
            'items-center',
            'min-w-full',
          ]
        : 'p-6'
    )}
  >
    <Spin size="large" />
  </div>
);

export default memo(Loading);
