import produce from 'immer';
import { Reducer } from 'redux';
import { isType } from 'typescript-fsa';

import {
  getUnreadMessagesCount,
  setUnreadMessagesCount,
} from './messenger.actions';

export interface State {
  unread_messages: number;
}

export const defaultState: State = {
  unread_messages: 0,
};

const reducer: Reducer<State> = (state = defaultState, action) => {
  return produce(state, (draft) => {
    if (isType(action, getUnreadMessagesCount.done)) {
      draft.unread_messages = action.payload.result.data.unread;
    }

    if (isType(action, setUnreadMessagesCount)) {
      draft.unread_messages = action.payload;
    }
  });
};

export default reducer;
