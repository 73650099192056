import { object, string } from 'yup';
import { ReportableTypes } from './types';

export const createFeedbackSchema = object()
  .shape({
    subject: string().required(),
    body: string().required(),
  })
  .defined();

export const createReportSchema = object().shape({
  reportable_type: string()
    .oneOf([...ReportableTypes])
    .required(),
  reportable_uuid: string().required(),
  report_type_uuid: string().required(),
  reason: string(),
});
