import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import usePrevious from 'src/hooks/usePrevious';

import { Dispatch } from 'src/types';

import { ApplicationState } from 'src/store';
import { redirectToLogin } from 'src/session';

import { refreshAuthToken } from 'src/store/authentication/authentication.requests';
import { handleInitTokens } from 'src/store/authentication/authentication.actions';

const AuthenticationWrapper: React.FC = ({ children }) => {
  const dispatch: Dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [showChildren, setShowChildren] = useState<boolean>(false);

  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.authentication.token
  );
  const wasAuthenticated = usePrevious(isAuthenticated);

  const userSiteState = useSelector(
    (state: ApplicationState) => state.authentication.site_state
  );

  // Mounted
  useEffect(() => {
    // Set redux state to what's in local storage or expire the tokens if the
    // expiry time is in the past.
    //
    // If nothing exists in local storage, we're redirected to login.
    dispatch(handleInitTokens());

    if (isAuthenticated) {
      setShowChildren(true);
    }
  }, [dispatch, isAuthenticated]);

  // Update.
  // Handles the case where we've refreshed the page and redux has now been
  // synced with what is in local storage.
  useEffect(() => {
    if (!wasAuthenticated && isAuthenticated && !showChildren) {
      // `isAuthenticated` actually just means the token is in the redux store.
      // We'll try and refresh the auth token immediately, which will either
      // succeed (and we're authed again) or fail, which will be caught in the
      // response interceptor and redirect back to the login page.
      dispatch(refreshAuthToken());

      setShowChildren(true);
    }

    // Handles the case where the token is expired or we got a 401 error.
    if (wasAuthenticated && !isAuthenticated) {
      redirectToLogin();
    }
  }, [wasAuthenticated, isAuthenticated, showChildren, dispatch]);

  // Check for site state issue.
  useEffect(() => {
    // User doesn't have a verified email, and we're not in the verify flow.
    if (
      userSiteState?.must_verify_email &&
      !location.pathname.startsWith('/email')
    ) {
      history.push('/email/verify');
    }
  }, [userSiteState, location, history]);

  return showChildren ? <>{children}</> : <></>;
};

export default AuthenticationWrapper;
