import actionCreatorFactory from 'typescript-fsa';
import { ApiErrorResponse, Resource } from 'src/api/types';
import { MessengerMessagesUpdated } from 'src/api/event-types';

const actionCreator = actionCreatorFactory('@@ABY/messenger');

export const getUnreadMessagesCount = actionCreator.async<
  void,
  Resource<MessengerMessagesUpdated>,
  ApiErrorResponse
>('request');

export const setUnreadMessagesCount = actionCreator<number>('update');
