import Request, { setData, setMethod, setURL } from 'src/api/request';
import { API_ROOT } from 'src/api/constants';
import {
  createForgotPasswordSchema,
  createLoginSchema,
  createRegisterSchema,
  createResetPasswordSchema,
} from './authentication.schema';
import { RequestSuccess } from 'src/api/types';
import {
  ForgotPasswordRequest,
  ForgotPasswordSuccess,
  LoginRequest,
  RegisterRequest,
  ResetPasswordRequest,
  LoginResponse,
  UserSiteState,
} from './types';

/**
 * Logs in the user and returns a Session containing the auth token.
 */
export const login = (data: LoginRequest) =>
  Request<LoginResponse>(
    setURL(`${API_ROOT}/auth/login`),
    setMethod('POST'),
    setData(data, createLoginSchema)
  ).then((response) => response.data);

/**
 * Refreshes the token.
 */
export const refresh = () =>
  Request<LoginResponse>(
    setURL(`${API_ROOT}/auth/refresh`),
    setMethod('POST')
  ).then((response) => response.data);

/**
 * Register a user.
 */
export const register = (data: RegisterRequest) =>
  Request<RequestSuccess>(
    setURL(`${API_ROOT}/auth/register`),
    setMethod('POST'),
    setData(data, createRegisterSchema)
  ).then((response) => response.data);

/**
 * Forgot password request.
 */
export const forgotPassword = (data: ForgotPasswordRequest) =>
  Request<ForgotPasswordSuccess>(
    setURL(`${API_ROOT}/auth/password/email`),
    setMethod('POST'),
    setData(data, createForgotPasswordSchema)
  ).then((response) => response.data);

/**
 * Reset the user's password.
 */
export const resetPassword = (data: ResetPasswordRequest) =>
  Request<LoginResponse>(
    setURL(`${API_ROOT}/auth/password/reset`),
    setMethod('POST'),
    setData(data, createResetPasswordSchema)
  ).then((response) => response.data);

/**
 * Resend verification email.
 */
export const resendEmailVerification = () =>
  Request<RequestSuccess>(
    setURL(`${API_ROOT}/auth/email/resend`),
    setMethod('POST')
  ).then((response) => response.data);

/**
 * Verify email.
 */
export const verifyEmail = (id: string, hash: string) =>
  Request<UserSiteState>(
    setURL(`${API_ROOT}/auth/email/verify/${id}/${hash}`),
    setMethod('POST')
  ).then((response) => response.data);

/**
 * Logs out the user on the API with the current JWT token.
 */
export const logout = () =>
  Request<RequestSuccess>(
    setURL(`${API_ROOT}/auth/logout`),
    setMethod('POST')
  ).then((response) => response.data);
