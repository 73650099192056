import Request, { setData, setMethod, setURL } from 'src/api/request';
import { API_ROOT } from 'src/api/constants';
import { updateAccountSchema } from './account.schema';
import { Account, UpdateAccountRequest } from './types';
import { Resource } from '../types';

export const getAccountInfo = () =>
  Request<Resource<Account>>(
    setURL(`${API_ROOT}/account`),
    setMethod('GET')
  ).then((response) => response.data);

/**
 * Update account.
 */
export const updateAccount = (data: UpdateAccountRequest) =>
  Request<Resource<Account>>(
    setURL(`${API_ROOT}/account`),
    setMethod('PUT'),
    setData(data, updateAccountSchema)
  ).then((response) => response.data);
