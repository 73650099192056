import { ReportType } from 'src/api/site';
import { ApiErrorResponse, ResourceCollection } from 'src/api/types';
import { actionCreatorFactory } from 'typescript-fsa';

export const actionCreator = actionCreatorFactory('@@ABY/report-type');

export const requestReportTypeActions = actionCreator.async<
  void,
  ResourceCollection<ReportType>,
  ApiErrorResponse
>('request');
