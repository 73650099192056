import { Seeking } from 'src/api/profile-choices';
import { Reducer } from 'redux';
import { isType } from 'typescript-fsa';
import { MappedEntityState } from 'src/store/types';
import {
  createDefaultState,
  onError,
  onGetAllSuccess,
  onStart,
} from 'src/store/store.helpers';
import { requestSeekingActions } from './seeking.actions';

/**
 * State
 */

export type State = MappedEntityState<Seeking>;

export const defaultState: State = createDefaultState();

const reducer: Reducer<State> = (state = defaultState, action) => {
  if (isType(action, requestSeekingActions.started)) {
    return onStart(state);
  }

  if (isType(action, requestSeekingActions.done)) {
    const { result } = action.payload;
    return onGetAllSuccess(result.data, state, result.data.length);
  }

  if (isType(action, requestSeekingActions.failed)) {
    const { error } = action.payload;
    return onError({ read: error }, state);
  }

  return state;
};

export default reducer;
