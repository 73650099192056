import { AsyncActionCreators } from 'typescript-fsa';
import { ApiErrorResponse } from 'src/api/types';
import {
  Entity,
  EntityError,
  MappedEntityState,
  ThunkActionCreator,
} from './types';

export const createRequestThunk = <Req extends any, Res, Err>(
  actions: AsyncActionCreators<Req, Res, Err>,
  request: (params: Req) => Promise<Res>
): ThunkActionCreator<Promise<Res>, Req> => {
  return (params: Req) => async (dispatch) => {
    const { started, done, failed } = actions;

    dispatch(started(params));

    try {
      const result = await request(params);
      const doneAction = done({ result, params });
      dispatch(doneAction);
      return result;
    } catch (error) {
      const failAction = failed({ error, params });
      dispatch(failAction);
      return Promise.reject(error);
    }
  };
};

export const createDefaultState = <E extends Entity, O extends EntityError>(
  override: Partial<MappedEntityState<E, O>> = {},
  defaultError: O = {} as O
): MappedEntityState<E, O> => ({
  itemsById: {},
  loading: false,
  lastUpdated: 0,
  error: defaultError as O,
  results: 0,
  ...override,
});

export const onStart = <S>(state: S) =>
  Object.assign({}, state, { loading: true, error: { read: undefined } });

export const onGetAllSuccess = <E extends Entity, S>(
  items: E[],
  state: S,
  results: number,
  update: (e: E) => E = (i) => i
): S =>
  Object.assign({}, state, {
    loading: false,
    lastUpdated: Date.now(),
    results,
    itemsById: items.reduce(
      (itemsById, item) => ({ ...itemsById, [item.uuid]: update(item) }),
      {}
    ),
  });

export const onError = <S = {}, E = ApiErrorResponse | undefined>(
  error: E,
  state: S
) => Object.assign({}, state, { error, loading: false });
