import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'src/store';

import Spin from 'src/components/core/Spin';

const SplashScreen: React.FC = () => {
  const appIsLoading = useSelector<ApplicationState>(
    (state) => state.initialLoad.appIsLoading
  );

  return appIsLoading ? (
    <div className="flex justify-center items-center fixed inset-0 min-h-screen min-w-full bg-white bg-opacity-75">
      <Spin size="large" />
    </div>
  ) : (
    <></>
  );
};

export default SplashScreen;
