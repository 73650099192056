import {
  getAccountProfile,
  updateAccountProfile as _updateAccountProfile,
} from 'src/api/profile';
import { createRequestThunk } from 'src/store/store.helpers';
import {
  getAccountProfileActions,
  handleUpdateAccountProfile,
} from './profile.actions';

export const requestAccountProfile = createRequestThunk(
  getAccountProfileActions,
  getAccountProfile
);

export const updateAccountProfile = createRequestThunk(
  handleUpdateAccountProfile,
  _updateAccountProfile
);
