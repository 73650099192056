import { Seeking } from 'src/api/profile-choices';
import { ApiErrorResponse, ResourceCollection } from 'src/api/types';
import { actionCreatorFactory } from 'typescript-fsa';

export const actionCreator = actionCreatorFactory('@@ABY/seeking');

export const requestSeekingActions = actionCreator.async<
  void,
  ResourceCollection<Seeking>,
  ApiErrorResponse
>('request');
