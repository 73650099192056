import { Identifiable } from '../types';

export interface Tag {
  name: string;
}

export interface ReportType extends Identifiable {
  label: string;
  weight: number;
}

export interface CreateFeedback {
  subject: string;
  body: string;
}

export const ReportableTypes = [
  'forum_reply',
  'forum_thread',
  'gallery_photo',
  'profile',
] as const;
export type ReportableType = typeof ReportableTypes[number];

export interface Reportable {
  reportable_type: ReportableType;
  reportable_uuid: string;
}

export interface CreateReport {
  reportable_type?: ReportableType;
  reportable_uuid?: string;
  report_type_uuid?: string;
  reason?: string;
}
