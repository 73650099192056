import { array, boolean, date, mixed, object, string } from 'yup';
import { DateTime } from 'luxon';

import i18n from 'src/i18n';

const noSlashes = /^[^/]+$/;
const socialOptions = {
  message: () => i18n.t('messages.social-username-only'),
  excludeEmptyString: true,
};

export const updateAccountProfileSchema = object()
  .shape({
    birthday: date()
      .nullable()
      .max(DateTime.local().minus({ years: 18 }).toISODate(), () =>
        i18n.t('messages.over-18')
      )
      .required(),
    gender: string().nullable(),
    orientation: string().nullable(),
    relationship_status: string().nullable(),
    relationship_roles: array().of(string()).nullable(),
    seeking: array().of(string()).nullable(),
    into: array().of(string()).nullable(),
    content_categories: array().of(string()).nullable(),
    communities: array().of(string()).nullable(),
    main_community: string().nullable(),
    height: string().nullable(),
    weight: string().nullable(),
    ethnicity: string().nullable(),
    pronouns: string().nullable(),
    about: string().nullable(),
    kinks: array().of(string()).nullable(),
    social_facebook: string().nullable().matches(noSlashes, socialOptions),
    social_instagram: string().nullable().matches(noSlashes, socialOptions),
    social_twitter: string().nullable().matches(noSlashes, socialOptions),
    social_tumblr: string().nullable().matches(noSlashes, socialOptions),
    location: string().nullable().required(),
    postal_code: string().nullable(),
  })
  .defined();

export const uploadMainImageSchema = object()
  .shape({
    has_image: boolean().required(),
    image: mixed().when('has_image', {
      is: (value: boolean) => value,
      then: mixed().nullable(),
      otherwise: mixed().nullable().defined().required(),
    }),
    profile_crop: string().nullable().required(),
    avatar_crop: string().nullable().required(),
  })
  .defined();
