import { RelationshipRole } from 'src/api/profile-choices';
import { ApiErrorResponse, ResourceCollection } from 'src/api/types';
import { actionCreatorFactory } from 'typescript-fsa';

export const actionCreator = actionCreatorFactory('@@ABY/relationship-role');

export const requestRelationshipRoleActions = actionCreator.async<
  void,
  ResourceCollection<RelationshipRole>,
  ApiErrorResponse
>('request');
