import Request, { setData, setMethod, setURL } from 'src/api/request';
import { API_ROOT } from 'src/api/constants';
import { Community } from './types';
import { ResourceCollection, RequestSuccess } from '../types';

export const getCommunities = () =>
  Request<ResourceCollection<Community>>(
    setURL(`${API_ROOT}/communities`),
    setMethod('GET')
  ).then((response) => response.data);

export const joinCommunity = (communityUuid: string) =>
  Request<RequestSuccess>(
    setURL(`${API_ROOT}/account/profile/communities`),
    setMethod('POST'),
    setData({ uuid: communityUuid })
  ).then((response) => response);

export const leaveCommunity = (communityUuid: string) =>
  Request<RequestSuccess>(
    setURL(`${API_ROOT}/account/profile/communities/${communityUuid}`),
    setMethod('DELETE')
  ).then((response) => response);
