import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Dispatch } from 'src/types';

import { handleLoadingDone } from 'src/store/initialLoad/initialLoad.actions';
import { requestAccount } from 'src/store/account/account.requests';
import { requestAccountProfile } from 'src/store/profile/profile.requests';
import { requestFriendRequestsCount } from 'src/store/friend-requests/friend-requests.requests';
import { requestCommunities } from 'src/store/communities/communities.requests';
import { requestReportTypes } from 'src/store/report-types/report-types.requests';
import { requestUnreadMessagesCount } from 'src/store/messenger/messenger.requests';

const DataLoader: React.FC = ({ children }) => {
  const dispatch: Dispatch = useDispatch();

  const makeInitialRequests = useCallback(async () => {
    const dataFetchingPromises: Promise<any>[] = [
      dispatch(requestAccount()),
      dispatch(requestAccountProfile()),
      dispatch(requestFriendRequestsCount()),
      dispatch(requestCommunities()),
      dispatch(requestReportTypes()),
      dispatch(requestUnreadMessagesCount()),
    ];

    try {
      await Promise.all(dataFetchingPromises);
    } catch {
      // Rely on redux error state.
    } finally {
      dispatch(handleLoadingDone());
    }
  }, [dispatch]);

  useEffect(() => {
    makeInitialRequests();
  }, [makeInitialRequests]);

  return <>{children}</>;
};

export default DataLoader;
