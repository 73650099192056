import Echo from 'laravel-echo';
import { Channel as EchoChannel } from 'laravel-echo/dist/channel';

import Pusher from 'pusher-js';

import {
  BROADCAST_AUTH,
  BROADCAST_HOST,
  BROADCAST_KEY,
  BROADCAST_TLS,
} from './constants';

export interface Channel extends EchoChannel {}

declare global {
  interface Window {
    Pusher: typeof Pusher;
  }
}

window.Pusher = Pusher;
window.Pusher.logToConsole = process.env.NODE_ENV === 'development';

const EchoClient = new Echo({
  broadcaster: 'pusher',
  key: BROADCAST_KEY,
  wsHost: BROADCAST_HOST,
  wssHost: BROADCAST_HOST,
  wsPort: 6001,
  wssPort: 6001,
  authEndpoint: BROADCAST_AUTH,
  auth: {
    headers: {
      Accept: 'application/json',
    },
  },
  enabledTransports: ['ws'],
  forceTLS: BROADCAST_TLS,
  enableStats: false,
});

/**
 * Update Echo auth token.
 *
 * @param token
 */
export const updateAuthToken = (token: string | null) => {
  if (token) {
    EchoClient.connector.pusher.config.auth.headers[
      'Authorization'
    ] = `Bearer ${token}`;
  } else {
    delete EchoClient.connector.pusher.config.auth.headers['Authorization'];
  }
};

export default EchoClient;
