import produce from 'immer';
import { Reducer } from 'redux';
import { isType } from 'typescript-fsa';

import {
  getFriendRequestsCount,
  setFriendRequestsCount,
} from './friend-requests.actions';

export interface State {
  count: number;
}

export const defaultState: State = {
  count: 0,
};

const reducer: Reducer<State> = (state = defaultState, action) => {
  return produce(state, (draft) => {
    if (isType(action, getFriendRequestsCount.done)) {
      draft.count = action.payload.result.meta.total;
    }

    if (isType(action, setFriendRequestsCount)) {
      draft.count = action.payload;
    }
  });
};

export default reducer;
