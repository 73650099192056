import Request, {
  setData,
  setMethod,
  setParams,
  setURL,
} from 'src/api/request';
import { API_ROOT } from 'src/api/constants';
import { CreateFeedback, CreateReport, ReportType, Tag } from './types';
import { RequestSuccess, ResourceCollection } from '../types';
import { createFeedbackSchema } from './site.schema';

export const searchTags = (term: string) =>
  Request<ResourceCollection<Tag>>(
    setURL(`${API_ROOT}/tags`),
    setMethod('GET'),
    setParams({ term })
  ).then((response) => response.data);

export const createFeedback = (data: CreateFeedback) =>
  Request<RequestSuccess>(
    setURL(`${API_ROOT}/feedback`),
    setMethod('POST'),
    setData(data, createFeedbackSchema)
  ).then((response) => response);

export const getReportTypes = () =>
  Request<ResourceCollection<ReportType>>(
    setURL(`${API_ROOT}/report-types`),
    setMethod('GET')
  ).then((response) => response.data);

export const reportContent = (data: CreateReport) =>
  Request<RequestSuccess>(
    setURL(`${API_ROOT}/reports`),
    setMethod('POST'),
    setData(data)
  ).then((response) => response);
